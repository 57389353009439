import Navbar from "../components/Navbar";
import aboutImg from "../assets/imgs/about.svg";
import ProfileCard from "../components/ProfileCard";
// Images
import akshayImg from "../assets/imgs/akshay.webp";
import sonaliImg from "../assets/imgs/sonali.jpg";
import alishaImg from "../assets/imgs/alisha.jpg";
import sreoImg from "../assets/imgs/sreo-sree.webp";
import shreyaImg from "../assets/imgs/shreya.jpg";
import arthavImg from "../assets/imgs/arthav.jpeg";
import arshImg from "../assets/imgs/arsh2.jpg";
import rohiniImg from "../assets/imgs/rohini.jpeg";
import rushikeshImg from "../assets/imgs/Rushikesh.jpg";
import halfMoon from "../assets/imgs/halfmoon.svg";
import storyImg from "../assets/imgs/storyImg.svg";
import daimeter from "../assets/imgs/daimeter.svg";
import rightDaimeter from "../assets/imgs/rightdaimeter.svg";
import dots from "../assets/imgs/dots.svg";
import polygon from "../assets/imgs/Polygon 2.svg";
import ellipse1 from "../assets/imgs/Ellipse 3.svg";
import ellipse2 from "../assets/imgs/Ellipse 4.svg";
// Icons
import { GrApple } from "react-icons/gr";
import { FaGooglePlay } from "react-icons/fa";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import { toast } from "react-toastify";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  gsap.registerPlugin(ScrollTrigger);

  const textRef1 = useRef<HTMLDivElement>(null);
  const textRef2 = useRef<HTMLDivElement>(null);
  const textRef3 = useRef<HTMLDivElement>(null);
  const textRef4 = useRef<HTMLDivElement>(null);
  const iconRef1 = useRef<HTMLDivElement>(null);
  const iconRef2 = useRef<HTMLDivElement>(null);
  const triggerIconRef = useRef<HTMLDivElement>(null);

  const storyRef1 = useRef<HTMLDivElement>(null);
  const storyRef2 = useRef<HTMLDivElement>(null);
  const triggerStoryRef = useRef<HTMLDivElement>(null);

  const dreamRef1 = useRef<HTMLDivElement>(null);
  const growthRef2 = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const triggerCardRef = useRef<HTMLDivElement>(null);
  const profileCardRef1 = useRef<HTMLDivElement>(null);
  const profileLeftCardRef2 = useRef<HTMLDivElement>(null);
  const profileRightCardRef2 = useRef<HTMLDivElement>(null);
  const profileCardRef3 = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    gsap.from(iconRef1.current, {
      x: -200,
      duration: 1.8,
      opacity:0,
      scrollTrigger: {
        trigger: triggerIconRef.current,
        scroller: "body",
        start: "top 50%"
      },
    });
    gsap.from(iconRef2.current, {
      x: 200,
      duration: 1.8,
      opacity:0,
      scrollTrigger: {
        trigger: triggerIconRef.current,
        scroller: "body",
        start: "top 50%"
      },
    });

    //TEXT REF ANIMATION
    gsap.from(textRef1.current, {
      y: -200,
      duration: 2,
      opacity: 0,
      delay: 0.6,
    });
    gsap.from(textRef2.current, {
      y: 200,
      duration: 2,
      opacity: 0,
      delay: 0.6,
    });
    gsap.from(textRef3.current, {
      y: -160,
      duration: 1.8,
      scrollTrigger: {
        trigger: triggerIconRef.current,
        scroller: "body",
      },
    });
    // Story animations ref
    gsap.from(storyRef1.current, {
      x: -450,
      duration: 1.3,
      opacity: 0,
      delay: 0.5,
      scrollTrigger: {
        trigger: triggerStoryRef.current,
        scroller: "body",
        start: "top 61%",
      },
    });
    gsap.from(storyRef2.current, {
      x: 450,
      duration: 1.3,
      opacity: 0,
      delay: 0.5,
      scrollTrigger: {
        trigger: triggerStoryRef.current,
        scroller: "body",
        start: "top 61%",
      },
    });
    // Dream and Growth Animation
    gsap.from(dreamRef1.current, {
      y: -450,
      duration: 1.3,
      opacity: 0,
      delay: 0.3,
      scrollTrigger: {
        trigger: triggerRef.current,
        scroller: "body",
        start: "top 70%",
      },
    });
    gsap.from(growthRef2.current, {
      y: 450,
      duration: 1.3,
      opacity: 0,
      delay: 0.3,
      scrollTrigger: {
        trigger: triggerRef.current,
        scroller: "body",
        start: "top 70%",
      },
    });
    gsap.from(profileCardRef1.current, {
      y: -350,
      duration: 1.3,
      opacity: 0,
      delay: 0.3,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 70%",
      },
    });
    gsap.from(profileCardRef3.current, {
      y: 350,
      duration: 1.3,
      opacity: 0,
      delay: 1,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 20%",
      },
    });
    gsap.from(textRef4.current, {
      x: -350,
      duration: 1.3,
      opacity: 0,
      delay: 0.3,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 70%",
      },
    });
    gsap.from(profileLeftCardRef2.current, {
      x: -350,
      duration: 1.8,
      opacity: 0,
      delay: 0.8,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 70%",
      },
    });
    gsap.from(profileRightCardRef2.current, {
      x: 350,
      duration: 1.8,
      opacity: 0,
      delay: 0.8,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 70%",
      },
    });
  });


  const handleIosClick = () => {
    toast.warn(
      <div>
        <strong>Coming soon!</strong>
        {/* <div>
          The iOS version of our app will be available shortly. Stay tuned!
        </div> */}
        <div>
          Our app will be available shortly. Stay tuned!
        </div>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>About Us: A Confident Look at Our Growth!</title>
        <meta name="keywords" content="xampus, about us, team, career" />
      </Helmet>
      <Navbar styles="bg-[#080e28] border-b border-gray-800" />
      <div
        className="relative min-h-[50rem] md:min-h-screen flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat px-4 md:px-0"
        style={{ backgroundImage: `url(${aboutImg})` }}
      >
        <div
          className="relative z-10 text-center text-white mt-10 md:mt-0 mb-6 md:mb-12"
          ref={textRef1}
        >
          <h1 className="text-5xl lg:text-6xl font-bold">About Us</h1>
        </div>
        <div
          className="relative z-10 w-full md:w-[80%] space-y-6 md:space-y-10"
          ref={textRef2}
        >
          <p className="text-white text-left md:text-center text-base md:text-lg lg:text-xl tracking-wider">
            Xampus is the new age professional network designed specifically for
            India’s dynamic and diverse workforce. We aim to revolutionize the
            way professionals connect, collaborate, and grow their careers in
            the digital age.
          </p>

          <p className="text-white text-left md:text-center text-base md:text-lg lg:text-xl tracking-wider">
            India’s premier AI-enabled professional networking platform. Here,
            you can connect, collaborate, and cultivate your career with
            like-minded professionals from diverse industries. Whether you’re
            looking to unlock new opportunities, showcase your expertise, or
            elevate your professional journey, Xampus is the place to be. Join
            us today and take the next step towards realizing your career
            aspirations. Your future of professional growth and success starts
            here.
          </p>
        </div>
      </div>

      <div
        className="min-h-[40rem] relative grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-y-8 gap-x-8 px-8 md:px-4 py-[3rem] md:py-[6rem] bg-[#0f1a42]"
        ref={triggerStoryRef}
      >
        <img
          src={polygon}
          loading="lazy"
          alt="vector"
          className="absolute left-[10rem] bottom-[1rem] md:left-[12rem] lg:bottom-[2rem] lg:left-[15rem] h-[3.3rem] md:h-[5rem]"
        />

        <img
          src={ellipse1}
          loading="lazy"
          alt="vector"
          className="absolute top-0 left-0 h-[10rem] md:h-[18rem]"
        />

        <img
          src={ellipse2}
          loading="lazy"
          alt="vector"
          className="absolute top-[3rem] right-[2rem] md:top-[8rem] md:right-[6rem] lg:top-[10rem] lg:right-[8rem] h-[4rem] md:h-[8rem]"
        />

        <div className="flex justify-center items-center" ref={storyRef1}>
          <img
            src={storyImg}
            loading="lazy"
            alt="vector"
            className="h-[12rem] md:h-[14rem] lg:h-[18rem]"
          />
        </div>

        <div
          className="w-[95%] lg:w-[75%] text-start text-white"
          ref={storyRef2}
        >
          <h2 className="text-[2rem] md:text-[2.5rem] font-bold mb-6">
            Our Story
          </h2>
          <p className="text-base md:text-lg lg:text-xl tracking-wider">
            Founded in Dec 2023, Xampus was born out of a desire to create a more
            inclusive and tailored professional network for the Indian market.
            Recognizing the unique challenges and opportunities within India’s
            job market, our founders set out to build a platform that bridges
            the gap between talent and opportunity.
          </p>
        </div>
      </div>

      <div
        className="relative min-h-[50rem] flex flex-col overflow-hidden items-center pb-[6rem] bg-[#acc1da] space-y-[6rem]"
        ref={triggerRef}
      >
        <img
          src={halfMoon}
          loading="lazy"
          alt="vector"
          className="absolute h-[30rem] md:h-[40rem] lg:h-[55rem] bottom-2 lg:bottom-5"
        />
        <div
          className="w-11/12 md:w-4/5 lg:w-2/3 text-start md:text-center"
          ref={dreamRef1}
        >
          <h2 className="text-2xl md:text-3xl font-bold mb-6">
            We started with a dream
          </h2>
          <p className="text-base md:text-lg lg:text-xl">
            Xampus's dream is to revolutionize professional networking for
            India's diverse and dynamic workforce by creating a platform that
            facilitates meaningful connections, fosters collaboration, and
            supports career growth in the digital era. They aim to empower
            professionals to advance their careers and achieve their
            professional goals through a tailored and innovative networking
            experience.
          </p>
        </div>

        <div
          className="w-11/12 md:w-4/5 lg:w-2/3 text-start md:text-center"
          ref={growthRef2}
        >
          <h2 className="text-2xl md:text-3xl font-bold mb-6">Growth</h2>
          <p className="text-base md:text-lg lg:text-xl">
            Since our inception, we have grown into a vibrant community of over
            10 professionals from various industries and backgrounds. Our team
            of 10+ dedicated individuals works tirelessly to improve and expand
            our services.
          </p>
        </div>
      </div>

      <div
        className="min-h-screen relative text-center bg-[#f0f8ff] py-8 overflow-hidden"
        ref={triggerCardRef}
      >
        <img
          src={daimeter}
          alt="vector"
          loading="lazy"
          className="absolute top-0 left-0 h-[15rem] md:h-[25rem] lg:h-[30rem] z-0"
        />
        <img
          src={dots}
          alt="xampus-vector"
          loading="lazy"
          className="absolute top-5 md:top-16 right-5 md:right-14 h-[5rem] md:h-[8rem] lg:h-[10rem] z-0"
        />
        <img
          src={dots}
          alt="xampus-vector"
          loading="lazy"
          className="absolute bottom-5 md:bottom-10 left-5 h-[5rem] md:h-[8rem] lg:h-[10rem] z-0"
        />
        <img
          src={rightDaimeter}
          alt="xampus-vector"
          loading="lazy"
          className="absolute bottom-0 right-0 h-[15rem] md:h-[25rem] lg:h-[30rem] z-0"
        />

        <div ref={textRef4}>
          <h3 className="text-[1rem] md:text-[1.4rem] font-medium md:mb-2">
            Our Creative Team
          </h3>
          <h1 className="text-[2rem] md:text-[3rem] font-bold mb-10">
            Meet The Team
          </h1>
        </div>

        {/****************** Profile Cards  ********************/}

        {/* Upper Row with 2 Cards */}
        <div
          className="flex flex-wrap justify-center gap-8 px-4"
          ref={profileCardRef1}
        >
          <div className="flex flex-col items-center">
            <ProfileCard
              profileImg={akshayImg}
              name="Akshay Khandade"
              designation="Founder, CEO"
            />
          </div>
          <div className="flex flex-col items-center">
            <ProfileCard
              profileImg={sonaliImg}
              name="Sonali Khandade"
              designation="Co-founder"
            />
          </div>
        </div>

        {/* Center Row with 4 Cards */}
        <div className="flex flex-wrap justify-center gap-8 px-4 mt-8">
          <div className="flex gap-8" ref={profileLeftCardRef2}>
            <div className="flex flex-col items-center">
              <ProfileCard
                profileImg={arshImg}
                name="Arsh Ramgarhia"
                designation="Frontend Developer"
              />
            </div>
            <div className="flex flex-col items-center">
            <ProfileCard
              profileImg={alishaImg}
              name="Alisha Patekar"
              designation="Mobile App Tester"
            />
          </div>
          </div>

          <div className="flex gap-8" ref={profileRightCardRef2}>
            <div className="flex flex-col items-center">
              <ProfileCard
                profileImg={rohiniImg}
                name="Rohini Kamble"
                designation="UI UX Developer"
              />
            </div>
            <div className="flex flex-col items-center">
              <ProfileCard
                profileImg={arthavImg}
                name="Atharva Deokar"
                designation="UI UX Developer"
              />
            </div>
          </div>
        </div>

        {/* Last Row with 3 Cards */}
        <div
          className="flex flex-wrap justify-center gap-8 px-4 mt-8"
          ref={profileCardRef3}
        >
          <div className="flex flex-col items-center">
            <ProfileCard
              profileImg={shreyaImg}
              name="Shreya Salunke"
              designation="Product Developer"
            />
          </div>
          <div className="flex flex-col items-center">
            <ProfileCard
              profileImg={rushikeshImg}
              name="Rushikesh Darode"
              designation="Mobile Developer"
            />
          </div>
        </div>
      </div>

      <div
        className="min-h-[15rem] md:min-h-[25rem] flex flex-col justify-center overflow-hidden items-center bg-[#E6F2FC] gap-y-10 md:gap-y-16 px-4 md:px-8"
        ref={triggerIconRef}
      >
        <h2
          className="text-[2rem] md:text-[3rem] font-semibold text-center overflow-hidden"
          ref={textRef3}
        >
          Get it on
        </h2>
        <div className="flex gap-2 md:gap-6 flex-wrap justify-center overflow-hidden p-5">
          <div
            className="ios flex items-center gap-2 md:gap-4 px-3 py-2 md:px-8 md:py-5 text-[0.8rem] md:text-[1rem] font-semibold cursor-pointer text-black bg-[#fff] border border-black rounded-xl md:rounded-xl lg:rounded-3xl"
            ref={iconRef1} 
            onClick={handleIosClick}
          >
            <GrApple className="text-[1.5rem] md:text-[2rem] lg:text-[3rem]" />
            <p className="text-[1rem] md:text-lg lg:text-2xl font-normal">App Store</p>
          </div>

          {/* <a
            href="#"
            target="blank"
          > */}
            <div
              className="android flex items-center gap-2 md:gap-4 px-3 py-2 md:px-8 md:py-5 text-[0.8rem] md:text-[1rem] text-[#fff] font-semibold cursor-pointer bg-[#060F40] rounded-xl md:rounded-xl lg:rounded-3xl"
              ref={iconRef2}
              onClick={handleIosClick}
            >
              <FaGooglePlay className="text-[1.5rem] md:text-[2rem] lg:text-[2.8rem]" />
              <p className="text-[1rem] md:text-lg lg:text-2xl font-normal">Play Store</p>
            </div>
          {/* </a> */}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AboutUs;
